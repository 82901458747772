/* Editor specific styles*/

/**********
* Base styles
***********/
#commandbar-editor-main {
  box-sizing: border-box;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
}

#commandbar-editor-main > code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/**********
* Drawer style= overrides
***********/
.drawer-content {
  background: #f2f2f2;
  border-radius: 8px;
}

/*
Only show a box shadow if the drawer is open
Otherwise it bleeds into the user's app
*/
.drawer-open .drawer-content {
  box-shadow: -2px 0 40px rgba(0, 0, 0, 0.6);
}

.drawer-content-wrapper {
  border-right: 16px transparent solid;
  height: calc(100% - 32px) !important;
  box-shadow: none !important;
  background: transparent;
}

/**********
* Recorder styles and animations
***********/

.pulse-ring {
  content: '';
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: orangered;
  animation: pulsate infinite 1s;
}

@keyframes pulsate {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }

  100% {
    transform: scale(1.1, 1.1);
    opacity: 0.6;
  }
}

#recorder {
  animation: 0.7s ease-out scaleIn;
}

@keyframes scaleIn {
  0% {
    transform: scale(0.06);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

/**********
* Sortable table styles
***********/

.row-dragging {
  opacity: 0.5;
  z-index: 2147483647;
}

.row-dragging td {
  padding: 8px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.row-dragging-no-border {
  opacity: 0.6;
  z-index: 2147483647;
}

/**********
* Nav bar style
***********/

.nav-bar {
  flex-grow: 1;
  height: 100%;
}

/* Nav bar tabs styling */
.nav-bar-tab .anticon {
  margin-right: 0px !important;
}

.nav-bar > .ant-tabs-content-holder {
  position: relative;
}

.nav-bar .ant-tabs-content {
  height: 100%;
}

.nav-bar .ant-tabs-nav-wrap {
  display: flex;
  padding: 0px 24px;
  color: grey;
}

.nav-bar .ant-tabs-tab-active {
  background: white;
  border-radius: 4px;
  border: 0.5px solid #dadada !important;
}

.nav-bar .ant-tabs-tab {
  padding: 8px 12px;
  transition: all 0.3s ease;
  border: 0.5px solid transparent;
  line-height: 1;
  margin-right: 20px;
}

.nav-bar .ant-tabs-ink-bar {
  display: none;
}

.nav-bar .ant-tabs-nav {
  margin-bottom: 24px !important;
}

.ant-tabs {
  overflow-y: visible !important;
  height: 100%;
}

.ant-badge.tab-badge {
  position: absolute;
  top: 0;
  right: 0;
}

.nav-bar .ant-tabs-content-holder {
  background: #fafafa;
  height: 100%;
}

/* .ant-tabs-card .ant-tabs-tab-active {
  background: #ffffff !important;
} */

.ant-tabs-card .ant-tabs-nav {
  margin: 0px !important;
}

/****
* Misc
***/

.ant-message-custom-content {
  display: flex;
}

.ant-collapse-header {
  padding: 0px !important;
}

.ant-collapse-content-box {
  padding: 6px 7px !important;
}

.emoji-mart-anchors {
  display: none !important;
}

.blur {
  background: linear-gradient(217deg, rgba(20, 20, 20, 0.7), rgba(20, 20, 20, 0) 70.71%),
    linear-gradient(127deg, rgba(20, 20, 20, 0.5), rgba(20, 20, 20, 0) 70.71%),
    linear-gradient(336deg, rgba(20, 20, 20, 0.3), rgba(20, 20, 20, 0) 70.71%);
  border-radius: 6px;
  height: 100%;
  width: 100%;
}

.side-bar--windows {
  overflow-y: auto;
}

/* HACK: to hide a table content and just keep the header */
.dummy-table .ant-table-placeholder {
  display: none;
}

.RulesTab .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: black;
}

.RulesTab .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selection-item {
  font-weight: inherit;
}

.RulesTab .ant-input.ant-input-disabled {
  font-weight: inherit;
  color: black;
}

.RulesTab .ConditionRuleContextSelect__single-value {
  color: black;
}

.editable-row {
  cursor: pointer;
}

kbd {
  display: inline-flex;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid rgb(214, 217, 222);
  font-size: 11.05px;
  padding: 4px 7px;
  transition: all 0.3s ease 0s;
  color: rgba(0, 0, 0, 0.85);
  min-width: 24px;
  height: 24px;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  font-family: Inter;
  font-weight: 400;
  box-sizing: border-box;
}
