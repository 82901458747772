.emoji-mart {
  border: 0px !important;
  font-size: 12px;
}

.emoji-mart-bar {
  display: none;
}

.emoji-mart-search {
  margin: 0px 0px 10px 0px;
  padding: 0;
}

.emoji-mart-search-icon {
  padding: 3px 1px 0px 0px;
}

.emoji-mart-scroll {
  height: 310px;
  padding: 0;
}

.emoji-mart-search input {
  padding: 6px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.emoji-mart-search input:hover {
  border-color: #525157;
}

.emoji-mart-search input::placeholder {
  color: #bfbfbf;
}

.circle-picker>span>div {
  box-shadow: #dedede 0 0 5px inset;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.default-icon__wrapper button {
  transition: box-shadow 0.2s ease-in;
}

.default-icon__wrapper button:hover {
  box-shadow: #dedede 0 0 5px !important;
}
