/* background for antd menu items */

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 30px;
  line-height: 30px;
}

.ant-layout-sider {
  user-select: none;
}

.ant-menu,
.ant-menu-submenu-selected {
  font-weight: 500;
}

.ant-menu-submenu-title,
.ant-menu-item {
  transition: all 0.25s ease-in-out;
}
.ant-menu-item:hover {
  overflow: visible !important;
}

/* .ant-menu-submenu-title {
  opacity: 1;
} */

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 32px;
  line-height: 32px;
}

.ant-layout-sider-trigger {
  border-right: 1px solid rgb(218, 218, 218);
}

.content-menu-toc > div {
  opacity: 0.6;
  transition: color 0.2s;
}

.content-menu-toc > div:hover {
  opacity: 1;
}

.ant-menu-submenu-popup {
  display: none;
}
