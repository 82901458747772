:root {
  --primary: #2755f0;
}

.ant-layout-content {
  overflow: auto;
}

.ant-layout-content,
.ant-layout .site-layout {
  /* Required for sticky headers and components */
  overflow-x: visible !important;
}

#home .ant-layout-sider-children {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#home .ant-layout-sider-trigger {
  background: #f6f6f6;
}

#main-nav-menu .ant-menu-sub {
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  color: #3f424a;
}

/* Main menu styling */
#main-nav-menu {
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  color: #3f424a;
  /* font-weight: 600 !important;
  font-size: 12px; */
}

#main-nav-menu .ant-menu-submenu-title {
  margin: 0px !important;
  height: 34px;
  line-height: 34px;
  font-weight: 600;
}

#main-nav-menu a {
  color: #3f424a;
}

#main-nav-menu a:hover {
  color: #1e1e29;
}

#main-nav-menu .ant-menu-item-selected {
  background-color: #e6ecfe;
}

#main-nav-menu .ant-menu-item-selected svg path {
  fill: #e6ecfe;
  stroke: #072eab;
}

#main-nav-menu .ant-dropdown-menu-item {
  font-size: 14px;
  font-weight: 500;
}

#main-nav-menu .ant-dropdown-menu-item:hover {
  background-color: #ededee;
  color: #1e1e29;
}

#main-nav-menu .ant-dropdown-menu-item svg path {
  stroke: #7a7a85;
}

#main-nav-menu .ant-menu-item-selected {
  color: #031963;
  font-weight: 600;
}

#main-nav-menu a:hover {
  color: #1e1e29;
}

#main-nav-menu .ant-menu-item-selected a {
  color: #031963;
  font-weight: 600;
}

#main-nav-menu .ant-menu-submenu-selected .ant-menu-submenu-title {
  font-weight: 600;
  color: #031963;
}

#main-nav-menu .ant-menu-item {
  height: 34px;
  line-height: 34px;
  font-size: 14px;
}

#main-nav-menu .ant-menu-item:hover {
  background-color: #ededee;
  color: #1e1e29;
}

#main-nav-menu-bottom .ant-menu-item {
  height: 24px;
  line-height: 24px;
}

.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after {
  border: 0px !important;
}

.profile-menu-dropdown {
  width: 240px;
  left: 10px !important;
  position: fixed;
}

.profile-menu-dropdown .ant-dropdown-menu-item {
  padding-left: 17px;
  padding-right: 17px;
}

.profile-menu-dropdown .ant-dropdown-menu-item:hover {
  background-color: #ededee;
  color: #1e1e29;
}

.profile-menu-dropdown .ant-dropdown-menu-item {
  padding-top: 0px;
  padding-bottom: 0px;
  height: 30px;
  line-height: 30px;
}

/********************** Dashboard *******************/
.dashboard-stat-icon {
  font-size: 24px;
  color: var(--primary);
  margin-right: 20px;
  align-self: flex-start;
  margin-top: -5px;
}

.dashboard-stat {
  flex-grow: 1;
  margin-top: 5px;
}

.dashboard-stat .ant-statistic-content-value {
  font-size: 44px;
  font-weight: 800;
  color: var(--primary);
  margin-left: -44px;
  margin-top: 10px;
}

.dashboard-stat .ant-statistic-content {
  display: flex;
  justify-content: center;
}

.dashboard-stat-title {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.6);
}
