/**********
* Alert 
***********/
.ant-alert {
  font-size: 14px !important;
}

/**********
* Select 
***********/
.ant-select-selector {
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}


.ant-modal .ant-modal-confirm-btns {
  display: flex;
  justify-content: end;
}

.ant-modal .ant-btn-primary {
  border-radius: 6px; /* rounded-button: Assuming 6px */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.03); /* shadow-sm */
  font-weight: 500; /* font-medium */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* gap-2 */
  width: fit-content; /* w-fit */
  position: relative;
  top: 0px; /* top-0 */
  user-select: none; /* select-none */
  cursor: pointer; /* cursor-pointer */
}

.ant-modal .ant-btn-primary:active {
  top: 1px; /* active:top-[1px] */
}

.ant-modal .ant-btn-primary:focus {
  box-shadow: inset 0px -1px 3px 0px rgba(0, 0, 0, 0.08), inset 0px -1px 1px 0px rgba(0, 0, 0, 0.03), 0px 0px 0px 3px rgba(39, 84, 238, 0.16); /* focus:shadow-input */
  border-color: rgb(39, 84, 238); /* focus:border-blue700 */
}

.ant-modal .ant-btn-primary:focus-visible {
  outline: none; /* focus-visible:outline-none */
}

.ant-modal .ant-btn-primary {
  background-color: rgb(39, 84, 238); /* bg-blue700 */
  color: rgb(255, 255, 255); /* text-gray100 */
  border: 1px solid transparent; /* border, border-solid, border-transparent */
}

.ant-modal .ant-btn-primary:hover {
  background-color: rgb(32, 70, 198); /* hover:bg-blue800 */
}

.ant-modal .ant-btn-primary {
  padding: 8px 12px; /* py-2, px-3 */
  font-size: 14px; /* text-base */
  height: 32px; /* h-xxl: Assuming 32px */
}

.ant-modal .ant-btn-default {
  border-radius: 6px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: fit-content;
  position: relative;
  top: 0px;
  user-select: none;
  cursor: pointer;
}

.ant-modal .ant-btn-default:active {
  top: 1px;
}

.ant-modal .ant-btn-default:focus {
  box-shadow: inset 0px -1px 3px 0px rgba(0, 0, 0, 0.08), inset 0px -1px 1px 0px rgba(0, 0, 0, 0.03), 0px 0px 0px 3px rgba(39, 84, 238, 0.16);
  border-color: rgb(39, 84, 238);
}

.ant-modal .ant-btn-default:focus-visible {
  outline: none;
}

.ant-modal .ant-btn-default {
  background-color: rgb(255, 255, 255);
  color: rgb(81, 85, 95);
  border: 1px solid rgb(186, 187, 192);
}

.ant-modal .ant-btn-default:hover {
  color: rgb(13, 14, 16);
  border-color: rgb(142, 145, 152);
}

.ant-modal .ant-btn-default {
  padding: 8px 12px;
  font-size: 14px;
  height: 32px;
  background: white;
}

/**********
* Icon Button 
***********/

/* Icon only button style */
.ant-btn-icon-only:not(.ant-btn-background-ghost) {
  padding: 6px !important;
  width: revert;
}

/* Ghost icon button style: no padding */
.ant-btn-icon-only.ant-btn-background-ghost {
  padding: 0px !important;
}

/**********
* Table header styling
***********/

/* Caps, font, border */
.ant-table-thead {
  font-size: 11px;
  text-transform: uppercase;
}
.ant-table-thead > tr > th {
  border-bottom: 1px rgb(211, 211, 211) solid;
}

/* If a table is sorted, darken font*/
.ant-table-thead > tr > .ant-table-column-sort {
  color: rgb(20, 20, 20);
}

/* Header padding. Padding is applied differently if a column has sorters */
.ant-table-thead > tr > th:not(.ant-table-column-has-sorters),
.ant-table-column-sorters {
  padding-bottom: 7px !important;
}

.table-active-row,
.table-active-row:hover {
  background: #f2f2f2 !important;
}

/**********
* Badge styling
***********/

.ant-badge-count {
  background-color: white;
  box-shadow: none;
  border-radius: 4px;
}

/**********
* Alerts
***********/

.ant-alert-with-description .ant-alert-message {
  font-size: 16px !important;
  font-weight: 600;
}

.ant-alert-description > p {
  font-size: 16px;
  margin: 0px;
}

/**********
* Result
***********/
.ant-result-extra {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

/**********
* Globals
**********/
:root {
  --primary: #2755f0;
  --primary0: #f5f7ff;
  --primary100: #e6ecfe;
  --primary200: #c4d1fd;
  --primary300: #90a8f9;
  --primary400: #6887ee;
  --primary500: #456be8;
  --primary600: #2755f0;
  --primary700: #0e3fdd;
  --primary800: #072eab;
  --primary900: #031963;
  --primary1000: #030f38;
}

/**********
* Select
***********/
.ant-select-selector {
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}

/**********
* Icon Button
***********/

/* Icon only button style */
.ant-btn-icon-only:not(.ant-btn-background-ghost) {
  padding: 6px !important;
  width: revert;
}

/* Ghost icon button style: no padding */
.ant-btn-icon-only.ant-btn-background-ghost {
  padding: 0px !important;
}

/**********
* Table header styling
***********/

/* Caps, font, border */
.ant-table-thead {
  font-size: 11px;
  text-transform: uppercase;
}

.ant-table-thead > tr > th {
  border-bottom: 1px rgb(211, 211, 211) solid;
}

/* If a table is sorted, darken font*/
.ant-table-thead > tr > .ant-table-column-sort {
  color: rgb(20, 20, 20);
}

/* Override active sort icon color from dark grey*/
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #397dd5;
}

/* Header padding. Padding is applied differently if a column has sorters */
.ant-table-thead > tr > th:not(.ant-table-column-has-sorters),
.ant-table-column-sorters {
  padding-bottom: 7px !important;
}

/* Ant theme styles don't apply to sortable tables. This override applies to them */
.ant-table-tbody > tr:hover {
  background: var(--primary0);
}

/**********
* Ant tabs
***********/

.ant-tabs-card .ant-tabs-tab {
  border: 0px !important;
}

.ant-tabs-card .ant-tabs-tab:not(.ant-tabs-tab-active) {
  background: transparent !important;
}

/**********
* Ant Select
***********/

/**********
* Ant Select
***********/

.ant-select-dropdown {
  padding: 0;
  overflow: hidden;
  pointer-events: auto;
}

.ant-select-dropdown .ant-select-item-option {
  border-bottom: 0.5px solid rgba(3, 3, 56, 0.1);
  min-height: 40px;
  font-size: 12px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.ant-select-dropdown .ant-select-item-option-selected {
  background-color: var(--primary100);
  color: var(--primary);
}

.ant-select-dropdown .ant-select-item-option:hover {
  background-color: var(--primary0);
}

.ant-tabs-tabpane {
  width: 100%;
  position: absolute;
  overflow: hidden auto;
  height: 100%;
  background: rgb(247, 247, 248);
  border-radius: 0px 0px 8px 8px;
  width: 100%;
}
