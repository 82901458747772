.strikes-heatmap .strike:hover {
  /* Offset ring effect */
  box-shadow: 0 0 0 3px rgba(248, 113, 113, 0);

  /* Fade-in effect */
  animation: strikeFadeInEffect 0.25s ease forwards;
  animation-delay: 0.25s;
}

.strikes-heatmap .dismissed:hover {
  /* Offset ring effect */
  box-shadow: 0 0 0 3px rgba(231, 232, 234, 0);

  /* Fade-in effect */
  animation: dismissFadeInEffect 0.25s ease forwards;
  animation-delay: 0.25s;

}

.strikes-heatmap .completed:hover {
  /* Offset ring effect */
  box-shadow: 0 0 0 3px rgba(149, 217, 82, 0);

  /* Fade-in effect */
  animation: completedFadeInEffect 0.25s ease forwards;
  animation-delay: 0.25s;

}

/* Keyframes for fade-in animation */
@keyframes strikeFadeInEffect {
  to {
    box-shadow: 0 0 0 3px rgba(248, 113, 113, 0.5);
  }
}


@keyframes dismissFadeInEffect {
  to {
    box-shadow: 0 0 0 3px rgba(231, 232, 234, 0.5);
  }
}

@keyframes completedFadeInEffect {
  to {
    box-shadow: 0 0 0 3px rgba(149, 217, 82, 0.5);
  }
}
