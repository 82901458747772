/* tailwind.css */

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.\!visible {
  visibility: visible !important;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0px;
}

.inset-x-0 {
  left: 0px;
  right: 0px;
}

.inset-y-0 {
  top: 0px;
  bottom: 0px;
}

.inset-y-sm {
  top: 8px;
  bottom: 8px;
}

.-left-full {
  left: -100%;
}

.-top-full {
  top: -100%;
}

.bottom-0 {
  bottom: 0px;
}

.left-0 {
  left: 0px;
}

.left-1 {
  left: 0.25rem;
}

.left-1\/2 {
  left: 50%;
}

.left-2 {
  left: 0.5rem;
}

.left-\[4px\] {
  left: 4px;
}

.left-\[50\%\] {
  left: 50%;
}

.left-sm {
  left: 8px;
}

.right-0 {
  right: 0px;
}

.right-1 {
  right: 0.25rem;
}

.right-2 {
  right: 0.5rem;
}

.right-4 {
  right: 1rem;
}

.right-\[2px\] {
  right: 2px;
}

.right-\[6px\] {
  right: 6px;
}

.right-sm {
  right: 8px;
}

.top-0 {
  top: 0px;
}

.top-1\/2 {
  top: 50%;
}

.top-4 {
  top: 1rem;
}

.top-\[25\%\] {
  top: 25%;
}

.top-\[45\%\] {
  top: 45%;
}

.top-\[4px\] {
  top: 4px;
}

.top-\[50\%\] {
  top: 50%;
}

.top-sm {
  top: 8px;
}

.isolate {
  isolation: isolate;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.z-\[1\] {
  z-index: 1;
}

.z-max {
  z-index: 2147483647;
}

.m-0 {
  margin: 0px;
}

.m-xs {
  margin: 4px;
}

.m-xxs {
  margin: 2px;
}

.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.my-lg {
  margin-top: 16px;
  margin-bottom: 16px;
}

.my-md {
  margin-top: 12px;
  margin-bottom: 12px;
}

.my-sm {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-xl {
  margin-top: 24px;
  margin-bottom: 24px;
}

.my-xs {
  margin-top: 4px;
  margin-bottom: 4px;
}

.my-xxs {
  margin-top: 2px;
  margin-bottom: 2px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-2\.5 {
  margin-bottom: 0.625rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-auto {
  margin-left: auto;
}

.mt-0 {
  margin-top: 0px;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.\!table {
  display: table !important;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.\!hidden {
  display: none !important;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.h-0 {
  height: 0px;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-3 {
  height: 0.75rem;
}

.h-3\.5 {
  height: 0.875rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-64 {
  height: 16rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-\[14px\] {
  height: 14px;
}

.h-\[16px\] {
  height: 16px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[22px\] {
  height: 22px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[32px\] {
  height: 32px;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[48px\] {
  height: 48px;
}

.h-\[6px\] {
  height: 6px;
}

.h-\[72px\] {
  height: 72px;
}

.h-\[var\(--radix-select-trigger-height\)\] {
  height: var(--radix-select-trigger-height);
}

.h-auto {
  height: auto;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-lg {
  height: 16px;
}

.h-md {
  height: 12px;
}

.h-px {
  height: 1px;
}

.h-sm {
  height: 8px;
}

.h-xl {
  height: 24px;
}

.h-xxl {
  height: 32px;
}

.h-xxxxxl {
  height: 64px;
}

.max-h-40 {
  max-height: 10rem;
}

.max-h-96 {
  max-height: 24rem;
}

.min-h-\[400px\] {
  min-height: 400px;
}

.w-0 {
  width: 0px;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-3 {
  width: 0.75rem;
}

.w-3\.5 {
  width: 0.875rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-7 {
  width: 1.75rem;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-\[14px\] {
  width: 14px;
}

.w-\[16px\] {
  width: 16px;
}

.w-\[1px\] {
  width: 1px;
}

.w-\[22px\] {
  width: 22px;
}

.w-\[250px\] {
  width: 250px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[38\.25\%\] {
  width: 38.25%;
}

.w-\[48px\] {
  width: 48px;
}

.w-\[5\%\] {
  width: 5%;
}

.w-\[50\%\] {
  width: 50%;
}

.w-\[6px\] {
  width: 6px;
}

.w-\[95\%\] {
  width: 95%;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-lg {
  width: 16px;
}

.w-md {
  width: 12px;
}

.w-px {
  width: 1px;
}

.w-sm {
  width: 8px;
}

.w-xl {
  width: 24px;
}

.w-xxl {
  width: 32px;
}

.min-w-\[1ch\] {
  min-width: 1ch;
}

.min-w-\[300px\] {
  min-width: 300px;
}

.min-w-\[8rem\] {
  min-width: 8rem;
}

.min-w-\[var\(--radix-select-trigger-width\)\] {
  min-width: var(--radix-select-trigger-width);
}

.min-w-max {
  min-width: -moz-max-content;
  min-width: max-content;
}

.max-w-\[220px\] {
  max-width: 220px;
}

.max-w-\[250px\] {
  max-width: 250px;
}

.max-w-\[260px\] {
  max-width: 260px;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.caption-bottom {
  caption-side: bottom;
}

.border-collapse {
  border-collapse: collapse;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[-50\%\] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-50\%\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes custom-pulse {

  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: -135% 0%;
  }
}

.animate-custom-pulse {
  animation: custom-pulse 1.5s ease-in-out infinite;
}

@keyframes spin {

  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes transform-height-one {

  0% {
    transform: translateY(25%) scaleY(.5);
  }

  33% {
    transform: translateY(35%) scaleY(.3);
  }

  66% {
    transform: translateY(9%) scaleY(.85);
  }

  100% {
    transform: translateY(25%) scaleY(.5);
  }
}

.animate-transform-height-one {
  animation: transform-height-one 2s infinite linear(0 0%, 0 1.8%, 0.01 3.6%, 0.03 6.35%, 0.07 9.1%, 0.13 11.4%, 0.19 13.4%, 0.27 15%, 0.34 16.1%, 0.54 18.35%, 0.66 20.6%, 0.72 22.4%, 0.77 24.6%, 0.81 27.3%, 0.85 30.4%, 0.88 35.1%, 0.92 40.6%, 0.94 47.2%, 0.96 55%, 0.98 64%, 0.99 74.4%, 1 86.4%, 1 100%) forwards;
}

@keyframes transform-height-three {

  0% {
    transform: translateY(25%) scaleY(.5);
  }

  33% {
    transform: scaleY(1);
  }

  66% {
    transform: translateY(9%) scaleY(.85);
  }

  100% {
    transform: translateY(25%) scaleY(.5);
  }
}

.animate-transform-height-three {
  animation: transform-height-three 2s infinite linear(0 0%, 0 1.8%, 0.01 3.6%, 0.03 6.35%, 0.07 9.1%, 0.13 11.4%, 0.19 13.4%, 0.27 15%, 0.34 16.1%, 0.54 18.35%, 0.66 20.6%, 0.72 22.4%, 0.77 24.6%, 0.81 27.3%, 0.85 30.4%, 0.88 35.1%, 0.92 40.6%, 0.94 47.2%, 0.96 55%, 0.98 64%, 0.99 74.4%, 1 86.4%, 1 100%) forwards;
}

@keyframes transform-height-two {

  0% {
    transform: scaleY(1);
  }

  33% {
    transform: translateY(25%) scaleY(.5);
  }

  66% {
    transform: translateY(20%) scaleY(.6);
  }

  100% {
    transform: scaleY(1);
  }
}

.animate-transform-height-two {
  animation: transform-height-two 2s infinite linear(0 0%, 0 1.8%, 0.01 3.6%, 0.03 6.35%, 0.07 9.1%, 0.13 11.4%, 0.19 13.4%, 0.27 15%, 0.34 16.1%, 0.54 18.35%, 0.66 20.6%, 0.72 22.4%, 0.77 24.6%, 0.81 27.3%, 0.85 30.4%, 0.88 35.1%, 0.92 40.6%, 0.94 47.2%, 0.96 55%, 0.98 64%, 0.99 74.4%, 1 86.4%, 1 100%) forwards;
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.resize-y {
  resize: vertical;
}

.resize-x {
  resize: horizontal;
}

.resize {
  resize: both;
}

.list-none {
  list-style-type: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-items-center {
  justify-items: center;
}

.gap-0 {
  gap: 0px;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-lg {
  gap: 16px;
}

.gap-sm {
  gap: 8px;
}

.gap-xs {
  gap: 4px;
}

.gap-xxs {
  gap: 2px;
}

.gap-x-2 {
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-avatarLg {
  border-radius: 12px;
}

.rounded-avatarMd {
  border-radius: 9.6px;
}

.rounded-avatarSm {
  border-radius: 7.2px;
}

.rounded-button {
  border-radius: 6px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: 8px;
}

.rounded-none {
  border-radius: 0;
}

.rounded-rounded {
  border-radius: 1000px;
}

.rounded-sm {
  border-radius: 4px;
}

.rounded-xs {
  border-radius: 2px;
}

.rounded-t-sm {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.rounded-br-button {
  border-bottom-right-radius: 6px;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0px;
}

.border-2 {
  border-width: 2px;
}

.border-\[0\] {
  border-width: 0;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-x-0 {
  border-left-width: 0px;
  border-right-width: 0px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0px;
}

.border-b-\[1px\] {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-r-0 {
  border-right-width: 0px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0px;
}

.border-t-\[1px\] {
  border-top-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity));
}

.border-blue300 {
  --tw-border-opacity: 1;
  border-color: rgb(182 198 250 / var(--tw-border-opacity));
}

.border-blue800 {
  --tw-border-opacity: 1;
  border-color: rgb(32 70 198 / var(--tw-border-opacity));
}

.border-borderPrimary {
  --tw-border-opacity: 1;
  border-color: rgb(235 235 235 / var(--tw-border-opacity));
}

.border-current {
  border-color: currentColor;
}

.border-elementBase {
  border-color: rgba(0, 0, 0, 0.08);
}

.border-elementDisabled {
  border-color: #00000014;
}

.border-elementMid {
  border-color: rgba(0, 0, 0, 0.16);
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray200 {
  --tw-border-opacity: 1;
  border-color: rgb(231 232 234 / var(--tw-border-opacity));
}

.border-gray300 {
  --tw-border-opacity: 1;
  border-color: rgb(208 209 212 / var(--tw-border-opacity));
}

.border-gray400 {
  --tw-border-opacity: 1;
  border-color: rgb(186 187 192 / var(--tw-border-opacity));
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgb(134 239 172 / var(--tw-border-opacity));
}

.border-green300 {
  --tw-border-opacity: 1;
  border-color: rgb(149 217 82 / var(--tw-border-opacity));
}

.border-green800 {
  --tw-border-opacity: 1;
  border-color: rgb(44 80 9 / var(--tw-border-opacity));
}

.border-orange-900 {
  --tw-border-opacity: 1;
  border-color: rgb(124 45 18 / var(--tw-border-opacity));
}

.border-orange300 {
  --tw-border-opacity: 1;
  border-color: rgb(254 215 170 / var(--tw-border-opacity));
}

.border-orange400 {
  --tw-border-opacity: 1;
  border-color: rgb(253 186 116 / var(--tw-border-opacity));
}

.border-red300 {
  --tw-border-opacity: 1;
  border-color: rgb(254 202 202 / var(--tw-border-opacity));
}

.border-red700 {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity));
}

.border-red800 {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
}

.border-red900 {
  --tw-border-opacity: 1;
  border-color: rgb(153 27 27 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: transparent;
}

.border-yellow700 {
  --tw-border-opacity: 1;
  border-color: rgb(202 138 4 / var(--tw-border-opacity));
}

.border-b-elementBase {
  border-bottom-color: rgba(0, 0, 0, 0.08);
}

.\!bg-elementBase {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.bg-\[\#080c1c\] {
  --tw-bg-opacity: 1;
  background-color: rgb(8 12 28 / var(--tw-bg-opacity));
}

.bg-\[\#393d49\] {
  --tw-bg-opacity: 1;
  background-color: rgb(57 61 73 / var(--tw-bg-opacity));
}

.bg-\[color\] {
  background-color: color;
}

.bg-backgroundInverse {
  --tw-bg-opacity: 1;
  background-color: rgb(30 30 30 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/30 {
  background-color: rgb(0 0 0 / 0.3);
}

.bg-blue100 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 241 254 / var(--tw-bg-opacity));
}

.bg-blue200 {
  --tw-bg-opacity: 1;
  background-color: rgb(216 224 255 / var(--tw-bg-opacity));
}

.bg-blue700 {
  --tw-bg-opacity: 1;
  background-color: rgb(39 84 238 / var(--tw-bg-opacity));
}

.bg-codeBackground {
  --tw-bg-opacity: 1;
  background-color: rgb(7 13 29 / var(--tw-bg-opacity));
}

.bg-contentDisabled {
  --tw-bg-opacity: 1;
  background-color: rgb(186 187 192 / var(--tw-bg-opacity));
}

.bg-contentMid {
  --tw-bg-opacity: 1;
  background-color: rgb(63 66 74 / var(--tw-bg-opacity));
}

.bg-elementBase {
  background-color: rgba(0, 0, 0, 0.08);
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gray200 {
  --tw-bg-opacity: 1;
  background-color: rgb(231 232 234 / var(--tw-bg-opacity));
}

.bg-green100 {
  --tw-bg-opacity: 1;
  background-color: rgb(230 246 214 / var(--tw-bg-opacity));
}

.bg-green200 {
  --tw-bg-opacity: 1;
  background-color: rgb(204 237 172 / var(--tw-bg-opacity));
}

.bg-green600 {
  --tw-bg-opacity: 1;
  background-color: rgb(73 134 14 / var(--tw-bg-opacity));
}

.bg-orange100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 237 / var(--tw-bg-opacity));
}

.bg-orange200 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}

.bg-red100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.bg-red200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-red700 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-surfaceBase {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-surfaceDisabled {
  --tw-bg-opacity: 1;
  background-color: rgb(240 240 240 / var(--tw-bg-opacity));
}

.bg-surfaceMid {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: transparent;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow600 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.bg-blueGradient {
  background-image: linear-gradient(90deg, #359AE3 5.11%, #359AE3 5.63%, #355CE3 18.65%, #0D1D56 100%);
}

.bg-darkBlueGradient {
  background-image: linear-gradient(118deg, #133199 26.12%, #4662C1 48.95%, #0F31A1 69.09%);
}

.bg-none {
  background-image: none;
}

.fill-backgroundInverse {
  fill: #1E1E1E;
}

.fill-blue700 {
  fill: #2754ee;
}

.fill-gray100 {
  fill: #ffffff;
}

.fill-gray600 {
  fill: #797c85;
}

.fill-gray700 {
  fill: #51555f;
}

.fill-red900 {
  fill: #991B1B;
}

.p-0 {
  padding: 0px;
}

.p-0\.5 {
  padding: 0.125rem;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-lg {
  padding: 16px;
}

.p-md {
  padding: 12px;
}

.p-sm {
  padding: 8px;
}

.p-xl {
  padding: 24px;
}

.p-xs {
  padding: 4px;
}

.p-xxl {
  padding: 32px;
}

.p-xxs {
  padding: 2px;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-lg {
  padding-left: 16px;
  padding-right: 16px;
}

.px-md {
  padding-left: 12px;
  padding-right: 12px;
}

.px-none {
  padding-left: 0;
  padding-right: 0;
}

.px-sm {
  padding-left: 8px;
  padding-right: 8px;
}

.px-xs {
  padding-left: 4px;
  padding-right: 4px;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-md {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.py-sm {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-xs {
  padding-top: 4px;
  padding-bottom: 4px;
}

.pb-lg {
  padding-bottom: 16px;
}

.pl-0 {
  padding-left: 0px;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-lg {
  padding-left: 16px;
}

.pl-sm {
  padding-left: 8px;
}

.pr-0 {
  padding-right: 0px;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-lg {
  padding-right: 16px;
}

.pr-xs {
  padding-right: 4px;
}

.ps-7 {
  padding-inline-start: 1.75rem;
}

.pt-0 {
  padding-top: 0px;
}

.pt-1 {
  padding-top: 0.25rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[85\%\] {
  font-size: 85%;
}

.text-base {
  font-size: 14px;
}

.text-lg {
  font-size: 18px;
}

.text-md {
  font-size: 16px;
}

.text-sm {
  font-size: 12px;
}

.text-xl {
  font-size: 22px;
}

.text-xs {
  font-size: 8px;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-\[1\.625\] {
  line-height: 1.625;
}

.leading-none {
  line-height: 1;
}

.leading-snug {
  line-height: 1.375;
}

.tracking-tight {
  letter-spacing: -0.025em;
}

.tracking-tighter {
  letter-spacing: -0.05em;
}

.\!text-gray100 {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-\[\#ffc979\] {
  --tw-text-opacity: 1;
  color: rgb(255 201 121 / var(--tw-text-opacity));
}

.text-blue500 {
  --tw-text-opacity: 1;
  color: rgb(110 141 245 / var(--tw-text-opacity));
}

.text-blue600 {
  --tw-text-opacity: 1;
  color: rgb(74 112 242 / var(--tw-text-opacity));
}

.text-blue700 {
  --tw-text-opacity: 1;
  color: rgb(39 84 238 / var(--tw-text-opacity));
}

.text-blue800 {
  --tw-text-opacity: 1;
  color: rgb(32 70 198 / var(--tw-text-opacity));
}

.text-blue900 {
  --tw-text-opacity: 1;
  color: rgb(26 56 157 / var(--tw-text-opacity));
}

.text-contentBase {
  --tw-text-opacity: 1;
  color: rgb(121 124 133 / var(--tw-text-opacity));
}

.text-contentDisabled {
  --tw-text-opacity: 1;
  color: rgb(186 187 192 / var(--tw-text-opacity));
}

.text-contentInverse {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-contentMid {
  --tw-text-opacity: 1;
  color: rgb(63 66 74 / var(--tw-text-opacity));
}

.text-contentTop {
  --tw-text-opacity: 1;
  color: rgb(13 14 16 / var(--tw-text-opacity));
}

.text-current {
  color: currentColor;
}

.text-gray100 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-gray1000 {
  --tw-text-opacity: 1;
  color: rgb(13 14 16 / var(--tw-text-opacity));
}

.text-gray200 {
  --tw-text-opacity: 1;
  color: rgb(231 232 234 / var(--tw-text-opacity));
}

.text-gray400 {
  --tw-text-opacity: 1;
  color: rgb(186 187 192 / var(--tw-text-opacity));
}

.text-gray600 {
  --tw-text-opacity: 1;
  color: rgb(121 124 133 / var(--tw-text-opacity));
}

.text-gray700 {
  --tw-text-opacity: 1;
  color: rgb(81 85 95 / var(--tw-text-opacity));
}

.text-gray800 {
  --tw-text-opacity: 1;
  color: rgb(63 66 74 / var(--tw-text-opacity));
}

.text-green500 {
  --tw-text-opacity: 1;
  color: rgb(89 162 18 / var(--tw-text-opacity));
}

.text-green700 {
  --tw-text-opacity: 1;
  color: rgb(58 106 11 / var(--tw-text-opacity));
}

.text-green800 {
  --tw-text-opacity: 1;
  color: rgb(44 80 9 / var(--tw-text-opacity));
}

.text-inherit {
  color: inherit;
}

.text-orange500 {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity));
}

.text-orange700 {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity));
}

.text-orange800 {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity));
}

.text-orange900 {
  --tw-text-opacity: 1;
  color: rgb(154 52 18 / var(--tw-text-opacity));
}

.text-red200 {
  --tw-text-opacity: 1;
  color: rgb(254 226 226 / var(--tw-text-opacity));
}

.text-red500 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-red700 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-red800 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.text-red900 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-90 {
  opacity: 0.9;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_0_2px_white\] {
  --tw-shadow: 0 0 0 2px white;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_5px_rgba\(128\2c 128\2c 128\2c 1\)\] {
  --tw-shadow: 0 0 5px rgba(128,128,128,1);
  --tw-shadow-colored: 0 0 5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_5px_rgba\(234\2c 179\2c 8\2c 1\)\] {
  --tw-shadow: 0 0 5px rgba(234,179,8,1);
  --tw-shadow-colored: 0 0 5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_5px_rgba\(255\2c 0\2c 0\2c 1\)\] {
  --tw-shadow: 0 0 5px rgba(255,0,0,1);
  --tw-shadow-colored: 0 0 5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_5px_rgba\(34\2c 128\2c 0\2c 1\)\] {
  --tw-shadow: 0 0 5px rgba(34,128,0,1);
  --tw-shadow-colored: 0 0 5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_5px_rgba\(56\2c 104\2c 255\2c 1\)\] {
  --tw-shadow: 0 0 5px rgba(56,104,255,1);
  --tw-shadow-colored: 0 0 5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.24), 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  --tw-shadow-colored: 0px 4px 16px 0px var(--tw-shadow-color), 0px 1px 1px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  --tw-shadow-colored: 0px 1px 8px 0px var(--tw-shadow-color), 0px 1px 1px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  --tw-shadow-colored: 0px 1px 3px 0px var(--tw-shadow-color), 0px 1px 1px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.outline {
  outline-style: solid;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-colors {
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-100 {
  transition-duration: 100ms;
}

.duration-200 {
  transition-duration: 200ms;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

@keyframes enter {

  from {
    opacity: var(--tw-enter-opacity, 1);
    transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
  }
}

@keyframes exit {

  to {
    opacity: var(--tw-exit-opacity, 1);
    transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
  }
}

.animate-in {
  animation-name: enter;
  animation-duration: 150ms;
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
}

.animate-out {
  animation-name: exit;
  animation-duration: 150ms;
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
}

.fade-in {
  --tw-enter-opacity: 0;
}

.fade-in-0 {
  --tw-enter-opacity: 0;
}

.fade-out {
  --tw-exit-opacity: 0;
}

.zoom-in-95 {
  --tw-enter-scale: .95;
}

.zoom-out-95 {
  --tw-exit-scale: .95;
}

.slide-in-from-bottom-2 {
  --tw-enter-translate-y: 0.5rem;
}

.slide-out-to-bottom-2 {
  --tw-exit-translate-y: 0.5rem;
}

.duration-100 {
  animation-duration: 100ms;
}

.duration-200 {
  animation-duration: 200ms;
}

.ease-in-out {
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ease-out {
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.running {
  animation-play-state: running;
}

:root {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.selection\:bg-blue200 *::-moz-selection {
  --tw-bg-opacity: 1;
  background-color: rgb(216 224 255 / var(--tw-bg-opacity));
}

.selection\:bg-blue200 *::selection {
  --tw-bg-opacity: 1;
  background-color: rgb(216 224 255 / var(--tw-bg-opacity));
}

.selection\:text-contentMid *::-moz-selection {
  --tw-text-opacity: 1;
  color: rgb(63 66 74 / var(--tw-text-opacity));
}

.selection\:text-contentMid *::selection {
  --tw-text-opacity: 1;
  color: rgb(63 66 74 / var(--tw-text-opacity));
}

.selection\:mix-blend-multiply *::-moz-selection {
  mix-blend-mode: multiply;
}

.selection\:mix-blend-multiply *::selection {
  mix-blend-mode: multiply;
}

.selection\:bg-blue200::-moz-selection {
  --tw-bg-opacity: 1;
  background-color: rgb(216 224 255 / var(--tw-bg-opacity));
}

.selection\:bg-blue200::selection {
  --tw-bg-opacity: 1;
  background-color: rgb(216 224 255 / var(--tw-bg-opacity));
}

.selection\:text-contentMid::-moz-selection {
  --tw-text-opacity: 1;
  color: rgb(63 66 74 / var(--tw-text-opacity));
}

.selection\:text-contentMid::selection {
  --tw-text-opacity: 1;
  color: rgb(63 66 74 / var(--tw-text-opacity));
}

.selection\:mix-blend-multiply::-moz-selection {
  mix-blend-mode: multiply;
}

.selection\:mix-blend-multiply::selection {
  mix-blend-mode: multiply;
}

.last\:border-r:last-child {
  border-right-width: 1px;
}

.focus-within\:relative:focus-within {
  position: relative;
}

.focus-within\:z-20:focus-within {
  z-index: 20;
}

.focus-within\:border-blue700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(39 84 238 / var(--tw-border-opacity));
}

.focus-within\:border-red700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity));
}

.focus-within\:shadow-input:focus-within {
  --tw-shadow:  0px 0px 0px 1px #2754EE inset, 0px 0px 0px 3px rgba(39, 84, 238, 0.16), 0px -1px 1px 0px rgba(0, 0, 0, 0.03) inset, 0px -1px 3px 0px rgba(0, 0, 0, 0.08) inset;
  --tw-shadow-colored: inset 0px 0px 0px 1px var(--tw-shadow-color), 0px 0px 0px 3px var(--tw-shadow-color), inset 0px -1px 1px 0px var(--tw-shadow-color), inset 0px -1px 3px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:shadow-inputError:focus-within {
  --tw-shadow: 0px 0px 0px 1px #B91C1C inset, 0px 0px 0px 3px rgba(220, 38, 38, 0.16), 0px -1px 1px 0px rgba(0, 0, 0, 0.03) inset, 0px -1px 3px 0px rgba(0, 0, 0, 0.08) inset;
  --tw-shadow-colored: inset 0px 0px 0px 1px var(--tw-shadow-color), 0px 0px 0px 3px var(--tw-shadow-color), inset 0px -1px 1px 0px var(--tw-shadow-color), inset 0px -1px 3px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:shadow-none:focus-within {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:rounded-bl-button:hover {
  border-bottom-left-radius: 6px;
}

.hover\:rounded-br-button:hover {
  border-bottom-right-radius: 6px;
}

.hover\:border:hover {
  border-width: 1px;
}

.hover\:border-solid:hover {
  border-style: solid;
}

.hover\:border-elementDisabled:hover {
  border-color: #00000014;
}

.hover\:border-elementMid:hover {
  border-color: rgba(0, 0, 0, 0.16);
}

.hover\:border-elementTop:hover {
  border-color: rgba(0, 0, 0, 0.30);
}

.hover\:border-gray300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(208 209 212 / var(--tw-border-opacity));
}

.hover\:border-gray500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(142 145 152 / var(--tw-border-opacity));
}

.hover\:border-red800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
}

.hover\:border-red900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(153 27 27 / var(--tw-border-opacity));
}

.hover\:border-transparent:hover {
  border-color: transparent;
}

.hover\:bg-backgroundHoverInverse:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(71 71 71 / var(--tw-bg-opacity));
}

.hover\:bg-blue100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(237 241 254 / var(--tw-bg-opacity));
}

.hover\:bg-blue200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(216 224 255 / var(--tw-bg-opacity));
}

.hover\:bg-blue700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(39 84 238 / var(--tw-bg-opacity));
}

.hover\:bg-blue800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(32 70 198 / var(--tw-bg-opacity));
}

.hover\:bg-elementBase:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.hover\:bg-gray100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-gray200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(231 232 234 / var(--tw-bg-opacity));
}

.hover\:bg-green200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(204 237 172 / var(--tw-bg-opacity));
}

.hover\:bg-orange200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}

.hover\:bg-surfaceDisabled:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 240 240 / var(--tw-bg-opacity));
}

.hover\:bg-surfaceMid:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}

.hover\:bg-transparent:hover {
  background-color: transparent;
}

.hover\:text-blue1000:hover {
  --tw-text-opacity: 1;
  color: rgb(19 41 117 / var(--tw-text-opacity));
}

.hover\:text-blue800:hover {
  --tw-text-opacity: 1;
  color: rgb(32 70 198 / var(--tw-text-opacity));
}

.hover\:text-blue900:hover {
  --tw-text-opacity: 1;
  color: rgb(26 56 157 / var(--tw-text-opacity));
}

.hover\:text-contentDisabled:hover {
  --tw-text-opacity: 1;
  color: rgb(186 187 192 / var(--tw-text-opacity));
}

.hover\:text-contentInverse:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:text-contentMid:hover {
  --tw-text-opacity: 1;
  color: rgb(63 66 74 / var(--tw-text-opacity));
}

.hover\:text-gray100:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:text-gray1000:hover {
  --tw-text-opacity: 1;
  color: rgb(13 14 16 / var(--tw-text-opacity));
}

.hover\:text-gray400:hover {
  --tw-text-opacity: 1;
  color: rgb(186 187 192 / var(--tw-text-opacity));
}

.hover\:text-gray600:hover {
  --tw-text-opacity: 1;
  color: rgb(121 124 133 / var(--tw-text-opacity));
}

.hover\:text-gray800:hover {
  --tw-text-opacity: 1;
  color: rgb(63 66 74 / var(--tw-text-opacity));
}

.hover\:text-green1000:hover {
  --tw-text-opacity: 1;
  color: rgb(17 31 3 / var(--tw-text-opacity));
}

.hover\:text-orange1000:hover {
  --tw-text-opacity: 1;
  color: rgb(124 45 18 / var(--tw-text-opacity));
}

.hover\:text-red1000:hover {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.hover\:text-red800:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.focus\:rounded-sm:focus {
  border-radius: 4px;
}

.focus\:border-blue700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(39 84 238 / var(--tw-border-opacity));
}

.focus\:border-red800:focus {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
}

.focus\:border-red900:focus {
  --tw-border-opacity: 1;
  border-color: rgb(153 27 27 / var(--tw-border-opacity));
}

.focus\:bg-blue700:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(39 84 238 / var(--tw-bg-opacity));
}

.focus\:bg-gray200:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(231 232 234 / var(--tw-bg-opacity));
}

.focus\:text-gray100:focus {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.focus\:shadow-button:focus {
  --tw-shadow: 0px 0px 0px 1px #2754EE, 0px 0px 0px 3px rgba(39, 84, 238, 0.16), 0px -1px 1px 0px rgba(0, 0, 0, 0.03), 0px -1px 3px 0px rgba(0, 0, 0, 0.08) #474747;
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color), 0px 0px 0px 3px var(--tw-shadow-color), 0px -1px 1px 0px var(--tw-shadow-color), 0px -1px 3px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-input:focus {
  --tw-shadow:  0px 0px 0px 1px #2754EE inset, 0px 0px 0px 3px rgba(39, 84, 238, 0.16), 0px -1px 1px 0px rgba(0, 0, 0, 0.03) inset, 0px -1px 3px 0px rgba(0, 0, 0, 0.08) inset;
  --tw-shadow-colored: inset 0px 0px 0px 1px var(--tw-shadow-color), 0px 0px 0px 3px var(--tw-shadow-color), inset 0px -1px 1px 0px var(--tw-shadow-color), inset 0px -1px 3px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-inputError:focus {
  --tw-shadow: 0px 0px 0px 1px #B91C1C inset, 0px 0px 0px 3px rgba(220, 38, 38, 0.16), 0px -1px 1px 0px rgba(0, 0, 0, 0.03) inset, 0px -1px 3px 0px rgba(0, 0, 0, 0.08) inset;
  --tw-shadow-colored: inset 0px 0px 0px 1px var(--tw-shadow-color), 0px 0px 0px 3px var(--tw-shadow-color), inset 0px -1px 1px 0px var(--tw-shadow-color), inset 0px -1px 3px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-sm:focus {
  --tw-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  --tw-shadow-colored: 0px 1px 3px 0px var(--tw-shadow-color), 0px 1px 1px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:outline-none:focus-visible {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus-visible\:ring-1:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px;
}

.active\:top-0:active {
  top: 0px;
}

.active\:top-\[1px\]:active {
  top: 1px;
}

.active\:top-\[7px\]:active {
  top: 7px;
}

.enabled\:hover\:bg-blue400:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(146 169 247 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-green400:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-red400:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(252 165 165 / var(--tw-bg-opacity));
}

.enabled\:hover\:bg-surfaceGradient:hover:enabled {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%, #F9F9F9);;
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:opacity-50:disabled {
  opacity: 0.5;
}

.aria-selected\:bg-blue700[aria-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(39 84 238 / var(--tw-bg-opacity));
}

.aria-selected\:bg-transparent[aria-selected="true"] {
  background-color: transparent;
}

.aria-selected\:text-contentDisabled[aria-selected="true"] {
  --tw-text-opacity: 1;
  color: rgb(186 187 192 / var(--tw-text-opacity));
}

.aria-selected\:opacity-100[aria-selected="true"] {
  opacity: 1;
}

.data-\[disabled\]\:pointer-events-none[data-disabled] {
  pointer-events: none;
}

.data-\[side\=bottom\]\:translate-y-1[data-side=bottom] {
  --tw-translate-y: 0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[side\=left\]\:-translate-x-1[data-side=left] {
  --tw-translate-x: -0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[side\=right\]\:translate-x-1[data-side=right] {
  --tw-translate-x: 0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[side\=top\]\:-translate-y-1[data-side=top] {
  --tw-translate-y: -0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[state\=checked\]\:translate-x-lg[data-state=checked] {
  --tw-translate-x: 16px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[state\=unchecked\]\:translate-x-\[0px\][data-state=unchecked] {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[swipe\=cancel\]\:translate-x-0[data-swipe=cancel] {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[swipe\=end\]\:translate-x-\[var\(--radix-toast-swipe-end-x\)\][data-swipe=end] {
  --tw-translate-x: var(--radix-toast-swipe-end-x);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[swipe\=move\]\:translate-x-\[var\(--radix-toast-swipe-move-x\)\][data-swipe=move] {
  --tw-translate-x: var(--radix-toast-swipe-move-x);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[state\=active\]\:rounded-tl-sm[data-state=active] {
  border-top-left-radius: 4px;
}

.data-\[state\=active\]\:rounded-tr-sm[data-state=active] {
  border-top-right-radius: 4px;
}

.data-\[state\=active\]\:border-\[1px\][data-state=active] {
  border-width: 1px;
}

.data-\[state\=active\]\:border-solid[data-state=active] {
  border-style: solid;
}

.data-\[state\=active\]\:border-elementBase[data-state=active] {
  border-color: rgba(0, 0, 0, 0.08);
}

.data-\[state\=checked\]\:border-blue900[data-state=checked] {
  --tw-border-opacity: 1;
  border-color: rgb(26 56 157 / var(--tw-border-opacity));
}

.data-\[state\=checked\]\:border-elementBase[data-state=checked] {
  border-color: rgba(0, 0, 0, 0.08);
}

.data-\[state\=checked\]\:border-elementDisabled[data-state=checked] {
  border-color: #00000014;
}

.data-\[state\=checked\]\:border-gray300[data-state=checked] {
  --tw-border-opacity: 1;
  border-color: rgb(208 209 212 / var(--tw-border-opacity));
}

.data-\[state\=unchecked\]\:border-elementMid[data-state=unchecked] {
  border-color: rgba(0, 0, 0, 0.16);
}

.data-\[state\=unchecked\]\:border-gray400[data-state=unchecked] {
  --tw-border-opacity: 1;
  border-color: rgb(186 187 192 / var(--tw-border-opacity));
}

.data-\[state\=active\]\:border-b-transparent[data-state=active] {
  border-bottom-color: transparent;
}

.data-\[highlighted\]\:bg-elementBase[data-highlighted] {
  background-color: rgba(0, 0, 0, 0.08);
}

.data-\[state\=active\]\:bg-surfaceMid[data-state=active] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}

.data-\[state\=checked\]\:bg-blue700[data-state=checked] {
  --tw-bg-opacity: 1;
  background-color: rgb(39 84 238 / var(--tw-bg-opacity));
}

.data-\[state\=checked\]\:bg-gray200[data-state=checked] {
  --tw-bg-opacity: 1;
  background-color: rgb(231 232 234 / var(--tw-bg-opacity));
}

.data-\[state\=checked\]\:bg-red700[data-state=checked] {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.data-\[state\=checked\]\:bg-surfaceDisabled[data-state=checked] {
  --tw-bg-opacity: 1;
  background-color: rgb(240 240 240 / var(--tw-bg-opacity));
}

.data-\[state\=checked\]\:bg-surfaceTop[data-state=checked] {
  --tw-bg-opacity: 1;
  background-color: rgb(231 232 234 / var(--tw-bg-opacity));
}

.data-\[state\=on\]\:bg-blue100[data-state=on] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 241 254 / var(--tw-bg-opacity));
}

.data-\[state\=on\]\:bg-gray100[data-state=on] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.data-\[state\=unchecked\]\:bg-gray100[data-state=unchecked] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.data-\[state\=unchecked\]\:bg-gray200[data-state=unchecked] {
  --tw-bg-opacity: 1;
  background-color: rgb(231 232 234 / var(--tw-bg-opacity));
}

.data-\[state\=unchecked\]\:bg-surfaceBase[data-state=unchecked] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.data-\[state\=active\]\:text-gray1000[data-state=active] {
  --tw-text-opacity: 1;
  color: rgb(13 14 16 / var(--tw-text-opacity));
}

.data-\[state\=on\]\:text-blue900[data-state=on] {
  --tw-text-opacity: 1;
  color: rgb(26 56 157 / var(--tw-text-opacity));
}

.data-\[state\=on\]\:text-gray1000[data-state=on] {
  --tw-text-opacity: 1;
  color: rgb(13 14 16 / var(--tw-text-opacity));
}

.data-\[disabled\]\:opacity-50[data-disabled] {
  opacity: 0.5;
}

.data-\[state\=active\]\:shadow-activeTab[data-state=active] {
  --tw-shadow: inset 0 -1px 0 0 #F9F9F9, 0 2px 0 -1px #F9F9F9;
  --tw-shadow-colored: inset 0 -1px 0 0 var(--tw-shadow-color), 0 2px 0 -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.data-\[state\=on\]\:shadow-none[data-state=on] {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.data-\[state\=on\]\:shadow-sm[data-state=on] {
  --tw-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  --tw-shadow-colored: 0px 1px 3px 0px var(--tw-shadow-color), 0px 1px 1px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.data-\[swipe\=move\]\:transition-none[data-swipe=move] {
  transition-property: none;
}

.data-\[state\=closed\]\:duration-300[data-state=closed] {
  transition-duration: 300ms;
}

.data-\[state\=open\]\:duration-500[data-state=open] {
  transition-duration: 500ms;
}

.data-\[state\=open\]\:animate-in[data-state=open] {
  animation-name: enter;
  animation-duration: 150ms;
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
}

.data-\[state\=closed\]\:animate-out[data-state=closed] {
  animation-name: exit;
  animation-duration: 150ms;
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
}

.data-\[swipe\=end\]\:animate-out[data-swipe=end] {
  animation-name: exit;
  animation-duration: 150ms;
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
}

.data-\[state\=closed\]\:fade-out-0[data-state=closed] {
  --tw-exit-opacity: 0;
}

.data-\[state\=closed\]\:fade-out-80[data-state=closed] {
  --tw-exit-opacity: 0.8;
}

.data-\[state\=open\]\:fade-in-0[data-state=open] {
  --tw-enter-opacity: 0;
}

.data-\[state\=closed\]\:zoom-out-95[data-state=closed] {
  --tw-exit-scale: .95;
}

.data-\[state\=open\]\:zoom-in-95[data-state=open] {
  --tw-enter-scale: .95;
}

.data-\[side\=bottom\]\:slide-in-from-top-2[data-side=bottom] {
  --tw-enter-translate-y: -0.5rem;
}

.data-\[side\=left\]\:slide-in-from-right-2[data-side=left] {
  --tw-enter-translate-x: 0.5rem;
}

.data-\[side\=right\]\:slide-in-from-left-2[data-side=right] {
  --tw-enter-translate-x: -0.5rem;
}

.data-\[side\=top\]\:slide-in-from-bottom-2[data-side=top] {
  --tw-enter-translate-y: 0.5rem;
}

.data-\[state\=closed\]\:slide-out-to-bottom[data-state=closed] {
  --tw-exit-translate-y: 100%;
}

.data-\[state\=closed\]\:slide-out-to-left[data-state=closed] {
  --tw-exit-translate-x: -100%;
}

.data-\[state\=closed\]\:slide-out-to-left-1\/2[data-state=closed] {
  --tw-exit-translate-x: -50%;
}

.data-\[state\=closed\]\:slide-out-to-right[data-state=closed] {
  --tw-exit-translate-x: 100%;
}

.data-\[state\=closed\]\:slide-out-to-right-full[data-state=closed] {
  --tw-exit-translate-x: 100%;
}

.data-\[state\=closed\]\:slide-out-to-top[data-state=closed] {
  --tw-exit-translate-y: -100%;
}

.data-\[state\=closed\]\:slide-out-to-top-\[48\%\][data-state=closed] {
  --tw-exit-translate-y: -48%;
}

.data-\[state\=open\]\:slide-in-from-bottom[data-state=open] {
  --tw-enter-translate-y: 100%;
}

.data-\[state\=open\]\:slide-in-from-left[data-state=open] {
  --tw-enter-translate-x: -100%;
}

.data-\[state\=open\]\:slide-in-from-left-1\/2[data-state=open] {
  --tw-enter-translate-x: -50%;
}

.data-\[state\=open\]\:slide-in-from-right[data-state=open] {
  --tw-enter-translate-x: 100%;
}

.data-\[state\=open\]\:slide-in-from-top[data-state=open] {
  --tw-enter-translate-y: -100%;
}

.data-\[state\=open\]\:slide-in-from-top-\[48\%\][data-state=open] {
  --tw-enter-translate-y: -48%;
}

.data-\[state\=open\]\:slide-in-from-top-full[data-state=open] {
  --tw-enter-translate-y: -100%;
}

.data-\[state\=closed\]\:duration-300[data-state=closed] {
  animation-duration: 300ms;
}

.data-\[state\=open\]\:duration-500[data-state=open] {
  animation-duration: 500ms;
}

.hover\:data-\[state\=unchecked\]\:border-gray500[data-state=unchecked]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(142 145 152 / var(--tw-border-opacity));
}

@media (min-width: 640px) {

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:rounded-md {
    border-radius: 8px;
  }

  .sm\:text-left {
    text-align: left;
  }

  .data-\[state\=open\]\:sm\:slide-in-from-bottom-full[data-state=open] {
    --tw-enter-translate-y: 100%;
  }
}

.\[\&\:has\(\>\.day-range-end\)\]\:rounded-r-md:has(>.day-range-end) {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.\[\&\:has\(\>\.day-range-start\)\]\:rounded-l-md:has(>.day-range-start) {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.\[\&\:has\(\[aria-selected\]\)\]\:rounded-md:has([aria-selected]) {
  border-radius: 8px;
}

.\[\&\:has\(\[aria-selected\]\)\]\:bg-blue700:has([aria-selected]) {
  --tw-bg-opacity: 1;
  background-color: rgb(39 84 238 / var(--tw-bg-opacity));
}

.first\:\[\&\:has\(\[aria-selected\]\)\]\:rounded-l-md:has([aria-selected]):first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.last\:\[\&\:has\(\[aria-selected\]\)\]\:rounded-r-md:has([aria-selected]):last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.\[\&\:has\(\[aria-selected\]\.day-outside\)\]\:bg-transparent:has([aria-selected].day-outside) {
  background-color: transparent;
}

.\[\&\:has\(\[aria-selected\]\.day-range-end\)\]\:rounded-r-md:has([aria-selected].day-range-end) {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.\[\&\>span\]\:line-clamp-1>span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.\[\&\>svg\:not\(\#cmdSpinner\)\]\:text-blue200>svg:not(#cmdSpinner) {
  --tw-text-opacity: 1;
  color: rgb(216 224 255 / var(--tw-text-opacity));
}

.\[\&\>svg\:not\(\#cmdSpinner\)\]\:text-gray400>svg:not(#cmdSpinner) {
  --tw-text-opacity: 1;
  color: rgb(186 187 192 / var(--tw-text-opacity));
}

.\[\&\>svg\:not\(\#cmdSpinner\)\]\:text-gray600>svg:not(#cmdSpinner) {
  --tw-text-opacity: 1;
  color: rgb(121 124 133 / var(--tw-text-opacity));
}

.\[\&\>svg\:not\(\#cmdSpinner\)\]\:text-gray800>svg:not(#cmdSpinner) {
  --tw-text-opacity: 1;
  color: rgb(63 66 74 / var(--tw-text-opacity));
}

.\[\&\>svg\]\:relative>svg {
  position: relative;
}

.\[\&\>svg\]\:top-\[1px\]>svg {
  top: 1px;
}

.\[\&\>svg\]\:flex-none>svg {
  flex: none;
}

.\[\&\>tr\]\:last\:border-b-0:last-child>tr {
  border-bottom-width: 0px;
}

.\[\&\[data-state\=closed\]\>svg\]\:rotate-180[data-state=closed]>svg {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\[data-state\=open\]\]\:border-elementBase[data-state=open] {
  border-color: rgba(0, 0, 0, 0.08);
}

.\[\&_p\]\:leading-relaxed p {
  line-height: 1.625;
}

.\[\&_tr\:last-child\]\:border-0 tr:last-child {
  border-width: 0px;
}

>.\[\>\&svg\]\:fill-gray600svg {
  fill: #797c85;
}