.CodeMirror {
  height: auto;
  font-family: 'Inter', proxima-nova, sans-serif;
  padding: var(--codemirror-padding, 9px);
  margin: var(--codemirror-margin, none);
  border-radius: 4px;
  border: var(--codemirror-border, 1px solid #d9d9d9);
  transition: 0.3s all;
  line-height: var(--codemirror-line-height, 22px);
  font-weight: var(--codemirror-font-weight, 400);
  min-height: 30px;
  max-height: 400px;
  overflow-x: hidden;
}

.CodeMirror-scroll {
  height: auto;
  max-height: 400px;
  overflow-x: hidden;
}

.CodeMirror:hover {
  border: var(--codemirror-border, 1px solid black);
}
.CodeMirror-line,
.CodeMirror-lines {
  padding: 0px;
}

/* Hint styles from retool */
.CodeMirror-hint-active {
  background: #ecf5fd !important;
  color: #3d3d3d !important;
  font-weight: 700;
}

.CodeMirror-hint {
  line-height: 14px;
  padding: 4px 6px;
  color: grey;
  border-radius: 0;
}

/* HINT MENU */
.CodeMirror-hints {
  min-width: 230px;
  position: absolute;
  z-index: 10;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 2px;
  box-shadow: 2px 3px 5px rgb(0 0 0 / 20%);
  border-radius: 3px;
  border: 1px solid silver;
  background: #fff;
  font-size: 12px;
  font-family: monospace;
  max-height: 20em;
  overflow-y: auto;
}

.CodeMirror-cursor {
  max-height: 20px;
  top: 5px !important;
  opacity: 0.9;
}

.CodeMirror-empty {
  color: grey;
}

.hint-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hint-addon {
  padding-left: 15px;
}
