html {
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
  width: 100vw;
}

#root {
  margin: 0;
  width: 100%;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 * See: https://css-tricks.com/international-box-sizing-awareness-day/;
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

.editor-layout .Resizer {
  background: #070d1d;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.editor-layout .Resizer:hover {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.editor-layout .Resizer.vertical {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.editor-layout .Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.editor-layout .Resizer.horizontal {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
.editor-layout .Resizer.horizontal:hover {
  border-top: 2px solid rgba(0, 0, 0, 0.5);
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}

.editor-layout .Resizer.disabled {
  cursor: not-allowed;
}
.editor-layout .Resizer.disabled:hover {
  border-color: transparent;
}

#contexts .ant-collapse-content {
  border: 0px solid transparent;
}

#contexts .ant-collapse-header {
  padding: 6px 40px 6px 16px;
}

.copy-icon svg {
  font-size: 12px !important;
}

.flip {
  transform: rotate(180deg);
}

.markdown-table {
  width: 100%;
  font-size: 16px;
}

.markdown-table td {
  width: auto;
  padding: 8px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  border-bottom: 1px solid rgb(230, 236, 241);
}

.markdown-table thead {
  border-bottom: 2px solid rgb(230, 236, 241);
}

.markdown-table th {
  padding: 8px;
  font-weight: 500;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  border-bottom: 1px solid #f2f2f2;
  color: #9daab6;
}

/* Override table action dropdown icon alignment */
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  vertical-align: sub;
}

/* message has no z-index prop, so need to set via css vs. inline */
.ant-message {
  z-index: 2147483647 !important;
}

@font-face {
  font-family: 'Apfel Grotezk';
  font-weight: 400;
  src: url('../fonts/apfelGrotezk/ApfelGrotezk-Regular.woff');
}

body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

code {
  white-space: pre-wrap;
}

a {
  word-break: break-word;
}

/* Override helphup launcher */
.intercom-messenger-frame {
  z-index: 2147483642 !important;
}
